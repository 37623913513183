import ReactGA from 'react-ga4';
const TRACKING_ID = "G-MEPQWS94W0";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="Landing centered-flex">
      <div className='content'>
          <h1>The FateFinder</h1>
          <h4>Coming Soon</h4>
          <p>
            Lual nears home after years on the run.  Only the North Stretch, mere days on trail, remain between him and the life he thought lost.  When treachery strikes, he's stretched beneath the heavens and gods, closer to them than home.  But then Lual, the Shield of West Fael, is transformed.  He gains a sight unknown to mortals and the Fates are laid bare to him.  Armed with this power Lual picks the paths that will reshape the lands of of his home.  With the death and life of Lual of West Fael, the fates spin and tangle about him.
          </p>
      </div>
    </div>
  );
}

export default App;